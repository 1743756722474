import React, { useRef } from "react";
import Header from "../../elements/Header/Header";
// basics
import Button from "../../basics/Button/Button";
// elements
import Footer from "../../elements/Footer/Footer";
import HeroImage from "../../../resources/images/building_1.jpg";
import DealImage from "../../../resources/images/deal.jpg";
import ContactImage from "../../../resources/images/contact.jpg";
import TextImage from "../../../resources/images/text.jpg";
import StepsContainer from "../../elements/StepsContainer/StepsContainer";
// styles
import styles from "./HomePage.module.css";
import ContactContainer from "../../elements/ContactContainer/ContactContainer";
import { BsWhatsapp } from "react-icons/bs";

const HomePage = () => {
  const contactRef = useRef(null);

  const scroll = (ref) => {
    ref?.current?.scrollIntoView();
  };

  return (
    <div className="page">
      <Header />
      <div className={`${styles.heroContainer}`}>
        <div className={`${styles.heroContent}`}>
          <div className="container">
            <h2>
              Vendre votre maison sans agent immobilier, ou laisser estimer
              votre maison gratuitement?
            </h2>
            <p>
              Vous souhaitez vendre votre maison rapidement et facilement sans
              agent immobilier? Appelez-nous ou envoyez un message via WhatsApp.
              Veuillez inclure quelques photos de la maison. Ensuite, nous
              venons visiter la propriété et nous donnons notre meilleure offre,
              nous donnons également immédiatement un accompte.
            </p>
            <Button label="Faites un rdv" onClick={() => scroll(contactRef)} />
          </div>
        </div>
        <div className={`${styles.heroOverlay}`}></div>
        <img src={HeroImage} alt="hero_img" />
      </div>

      <section
        className={`${styles.sectionContainer} ${styles.info}`}
        id="info"
      >
        <StepsContainer />
      </section>

      <section id="werkwijze">
        <div className="container">
          <h2>Façon de travailler</h2>
          <p>
            Il y a beaucoup à considérer lors de la vente d'une maison. Il y a
            aussi différentes parties impliquées. Si vous faites cela par
            l'intermédiaire d'un agent immobilier, une partie supplémentaire
            sera ajoutée. Vous devez également renoncer à une partie de la
            commission afin de ne pas obtenir la pleine valeur de votre maison.
          </p>
          <p>
            Nous Achetons Direct Votre Maison rend tout beaucoup plus facile. En
            envoyant un simple message via WhatsApp avec quelques informations
            et photos, nous pouvons vous donner une estimation du prix de la
            valeur de votre maison. Nous n'avons pas besoin d'une partie
            supplémentaire. Avez-vous décidé de commencer une vente avec nous?
            Ensuite, nous éliminons tous les tracas de la paperasse et
            organisons tout pour vous! Nous organisons même le déménagement dans
            votre nouveau logement afin que vous puissiez emménager en toute
            tranquillité après la vente de votre logement. Vous n'avez pas
            encore trouvé de nouveau chez-vous ou vous souhaitez un agencement
            différent ? Tout est négociable, alors n'hésitez pas à nous faire
            une proposition.
          </p>
          <div className="row">
            <div className={`${styles.serviceContent}`}>
              <div className={`${styles.seriviceImageWrapper}`}>
                <img src={TextImage} alt="service" />
              </div>
              <div className={`${styles.seriviceTextWrapper}`}>
                <h3>Appelez-nous ou envoyez un message via WhatsApp</h3>
                <p>
                  Envoyez-nous un message via WhatsApp avec les détails de votre
                  maison. N'oubliez pas d'envoyer des photos de la maison à
                  vendre. Nous regardons les photos, prenons rendez-vous pour
                  voir la maison et faisons une estimation de prix lors de la
                  visite et faisons une offre. Si vous acceptez l'offre, vous
                  recevrez immédiatement un accompte !
                </p>
                <a
                  href="https://wa.me/+32499199661?text=Je%20veux%20vendre!%20(Merci%20d%27envoyer%20des%20photos%20de%20la%20properiété)."
                  target="_blank"
                  rel="noopener noreferrer"
                  className={`${styles.ctaButton} ${styles.ctaWhatsApp}`}
                >
                  Envoyez un message WhatsApp! <BsWhatsapp />
                </a>
              </div>
            </div>
          </div>
          <div className="row">
            <div className={`${styles.serviceContent} ${styles.textLeft}`}>
              <div className={`${styles.seriviceImageWrapper}`}>
                <img src={ContactImage} alt="service" />
              </div>
              <div className={`${styles.seriviceTextWrapper}`}>
                <h3>Obtenez une réponse de notre part dans les 12 heures</h3>
                <p>
                  Vous recevrez une réponse de notre part sous 12 heures pour
                  prendre rendez-vous. Lors de ce rendez-vous, vous recevrez
                  immédiatement un devis et une offre. Grâce à notre formation
                  et à nos années d'expérience, nous savons exactement où se
                  situe votre maison sur le marché et nous pouvons vous fournir
                  une estimation de prix correcte. Peu importe à quel point
                  votre maison est pleine ou vide, encombrée ou rangée. Grâce à
                  notre expertise, nous pouvons examiner la structure du
                  bâtiment et faire une offre correcte basée sur nos
                  commentaires. Vous recevrez immédiatement une offre lors de la
                  visite. Vous aimez cette offre ? Nous vous fournirons
                  immédiatement une avance! Vous attendiez-vous à quelque chose
                  de différent ? Pas de problème, vous pourrez alors nous faire
                  une contre-offre ou demander une nouvelle offre.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className={`${styles.serviceContent}`}>
              <div className={`${styles.seriviceImageWrapper}`}>
                <img src={DealImage} alt="service" />
              </div>
              <div className={`${styles.seriviceTextWrapper}`}>
                <h3>Nous nous occupons entièrement de la vente</h3>
                <p>
                  Avez-vous été satisfait de notre estimation de prix et de
                  notre offre et avez-vous décidé de vendre ? Merci pour votre
                  confiance ! Nous commençons par préparer tous les papiers et
                  certificats nécessaires à nos frais, à savoir toutes les
                  attestations nécessaires pour la vente.
                </p>
                <div className={styles.tableSection}>
                  <span>Certificat de test d'électricité</span>
                  <span>Certificat EPC</span>
                </div>
                <div className={styles.tableSection}>
                  <span>Matière du sol</span>
                  <span>Certificat de réservoir de mazout</span>
                </div>
                <div className={styles.tableSection}>
                  <span>Plan cadastral</span>
                  <span>Sensibilité aux inondations</span>
                </div>
                <p>
                  Une fois que tous les papiers sont en règle, nous vous aidons
                  également dans votre déménagement. Nous veillons à ce que tous
                  vos biens soient placés en toute sécurité dans votre nouvelle
                  maison. C'est un souci de moins pour vous aussi !
                </p>
                <p>
                  Vous préférez vendre votre maison pour ne plus avoir de frais
                  fixes et ensuite la louer à nouveau ? C'est également possible
                  chez Nous Achetons Direct Votre Maison Faites-nous une
                  proposition et nous vérifierons si cela est réaliste. Nous
                  écoutons vos besoins et essayons de trouver avec vous la
                  meilleure solution possible.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        ref={contactRef}
        className={`${styles.sectionContainer}`}
        id="contact"
      >
        <ContactContainer />
      </section>

      <Footer />
    </div>
  );
};

export default HomePage;
