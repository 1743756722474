import React from "react";

import { BsWhatsapp } from "react-icons/bs";
import { FiMail } from "react-icons/fi";

import Button from "../../basics/Button/Button";

import styles from "./ContactContainer.module.css";

const ContactContainer = ({ showForm }) => {
  return (
    <div className={styles.contactContainer}>
      <div className="container">
        <div className="row">
          <div
            className={`col-12 col-md-12 col-lg-6 col-xl-6 ${styles.contactInfoWrapper}`}
          >
            <h2>Contact</h2>
            <p>
              Envie d'une vente sans soucis ? Envoyez un message au{" "}
              <strong>0499/199.661</strong>
              ou appelez-nous dès aujourd'hui !
            </p>

            <div className={styles.ctaWrapper}>
              <ul>
                <li>
                  <a
                    href="https://wa.me/+32499199661?text=Je%20veux%20vendre!%20(Merci%20d%27envoyer%20des%20photos%20de%20la%20properiété)."
                    target="_blank"
                    rel="noopener noreferrer"
                    className={`${styles.ctaButton} ${styles.ctaWhatsApp}`}
                  >
                    Envoyez un message WhatsApp! <BsWhatsapp />
                  </a>
                </li>
                <li>
                  <a
                    href="mailto:nousachetonsdirectvotremaison@outlook.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    className={`${styles.ctaButton}  ${styles.ctaMail}`}
                  >
                    Envoyez-nous un e-mail! <FiMail />
                  </a>
                </li>
              </ul>
            </div>
          </div>
          {showForm ? (
            <div
              className={`col-12 col-md-12 col-lg-6 col-xl-6 ${styles.contactFormWrapper}`}
            >
              <div className={styles.formWrapper}>
                <form action="submit">
                  <h2>Stuur ons een berichtje!</h2>
                  <input
                    className={styles.formInput}
                    type="text"
                    placeholder="Email..."
                  />
                  <textarea
                    className={`${styles.formInput} ${styles.formTextArea}`}
                    rows="10"
                    placeholder="Bericht..."
                  ></textarea>
                  <Button label="Verzenden" type="submit" />
                </form>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};

export default ContactContainer;
