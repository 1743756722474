import React from "react";

import { BsCashStack } from "react-icons/bs";
import { IoMdContact } from "react-icons/io";
import { MdEditNote } from "react-icons/md";

import styles from "./StepsContainer.module.css";

const StepsContainer = () => {
  return (
    <div className={styles.stepsWrapper}>
      <div className={`container ${styles.stepsContainer}`}>
        <div className="row">
          <div
            className={`col-12 col-md-12 col-lg-4 col-xl-4 ${styles.stepWrapper}`}
          >
            <div className={styles.logoWrapper}>
              <IoMdContact />
            </div>
            <div className={styles.textWrapper}>
              <h3>Contactez nous</h3>
              <p>Contactez-nous via Whatsapp ou envoyez-nous un e-mail</p>
            </div>
          </div>
          <div
            className={`col-12 col-md-12 col-lg-4 col-xl-4 ${styles.stepWrapper}`}
          >
            <div className={styles.logoWrapper}>
              <MdEditNote />
            </div>
            <div className={styles.textWrapper}>
              <h3>Proposition</h3>
              <p>
                Nous vous proposons une proposition personnalisée en fonction de
                la conversation et de vos besoins.
              </p>
            </div>
          </div>
          <div
            className={`col-12 col-md-12 col-lg-4 col-xl-4 ${styles.stepWrapper}`}
          >
            <div className={styles.logoWrapper}>
              <BsCashStack />
            </div>
            <div className={styles.textWrapper}>
              <h3>Accompte</h3>
              <p>
                Vous recevrez immédiatement votre avance et le solde chez le
                notaire.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StepsContainer;
